import NetworkService, { Endpoint } from "./NetworkService";

export default class CategoryService {
  network
  constructor() {
    this.network = new NetworkService();
  }
  list(params: any = {}) {
    return this.network.get(Endpoint.categories, params);
  }
  async create(params: any) {
    const res = await this.network.post(`${Endpoint.categories}`, params);
    return {
      id: res.data.id,
      title: res.data.title,
    }
  }
  update(id: any, params: any) {
    return this.network.put(`${Endpoint.categories}/${id}`, params);
  }
  delete(id: any) {
    return this.network.delete(`${Endpoint.categories}/${id}`);
  }
}
