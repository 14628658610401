<template>
  <ConfirmationAlert />
  <NotificationToast />
  <ErrorAlertComponent />
  <router-view />
</template>
<script>
import { defineComponent } from 'vue'
import NotificationToast from '@/components/NotificationToast.vue'
import ErrorAlertComponent from '@/components/ErrorAlertComponent.vue';
import * as pkg from "../package.json";
export default defineComponent({

  created() {
    document.title = `${process.env.VUE_APP_APP_NAME.toUpperCase()}`
  },
  mounted() {
    console.log(
      "VERSION:",
      `${pkg.version} - ${process.env.VUE_APP_ENV_STRING}`
    );
  },
  components: {
    NotificationToast,
    ErrorAlertComponent,
  }

})
</script>
