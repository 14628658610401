import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";
import { countries } from "@/utils/countries";
import { IProductPost, IProductUpdate } from "@/interfaces";

export default class ProductService {
  network
  constructor() {
    this.network = new NetworkService();
  }
  create(params: IProductPost) {
    return this.network.postMultipart(Endpoint.products, params);
  }
  update(id: string | number, params: IProductUpdate) {
    return this.network.postMultipart(`${Endpoint.products}/${id}/update`, params);
  }
  list(params: any) {
    return this.network.get(Endpoint.products, params);
  }
  show(id: any) {
    return this.network.get(`${Endpoint.products}/${id}`);
  }
  delete(id: any) {
    return this.network.delete(`${Endpoint.products}/${id}`);
  }
  prepareProduct(item: any) {
    try {
      item.formatted_image = `${process.env.VUE_APP_BASE_IMG_URL}${item.image}`
      const country = item.attributes ? item.attributes.find((r: any) => r.type == 'country') : null
      if (country) {
        item.country_name = country.value
        const flag = countries.find((elem: any) => elem.name == country.value)
        if (flag) {
          item.country_flag = flag.flag
        }
      }
      item.formatted_category = (item.categories && item.categories?.length) ? item.categories[0] : {}
      item.formatted_price = (item.price || 0).toCurrency(item.price)

    } catch (e) {
      console.log(e)
    } finally {
      return item
    }
  }
}
