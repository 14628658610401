<template>
  <td>
    <a :href="href">
      <slot></slot>
    </a>
  </td>
</template>

<script lang="ts">
export default {
  props: {
    href: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      typingTimer: null
    }
  },
}
</script>

<style></style>
