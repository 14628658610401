export const countries = [
  {
    "code": "AR",
    "flag": "🇦🇷",
    "name": "Argentina"
  },
  {
    "code": "AT",
    "flag": "🇦🇹",
    "name": "Áustria"
  },
  {
    "code": "AU",
    "flag": "🇦🇺",
    "name": "Austrália"
  },
  {
    "code": "BR",
    "flag": "🇧🇷",
    "name": "Brasil"
  },
  {
    "code": "CL",
    "flag": "🇨🇱",
    "name": "Chile"
  },
  {
    "code": "FR",
    "flag": "🇫🇷",
    "name": "França"
  },
  {
    "code": "DE",
    "flag": "🇩🇪",
    "name": "Alemanha"
  },
  {
    "code": "GR",
    "flag": "🇬🇷",
    "name": "Grécia"
  },
  {
    "code": "HU",
    "flag": "🇭🇺",
    "name": "Hungria"
  },
  {
    "code": "IT",
    "flag": "🇮🇹",
    "name": "Itália"
  },
  {
    "code": "NZ",
    "flag": "🇳🇿",
    "name": "Nova Zelândia"
  },
  {
    "code": "PT",
    "flag": "🇵🇹",
    "name": "Portugal"
  },
  {
    "code": "ES",
    "flag": "🇪🇸",
    "name": "Espanha"
  },
  {
    "code": "ZA",
    "flag": "🇿🇦",
    "name": "África do Sul"
  },
  {
    "code": "US",
    "flag": "🇺🇸",
    "name": "Estados Unidos"
  },
  {
    "code": "GB",
    "flag": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "name": "Escócia"
  }
]
