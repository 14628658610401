<template>
    <div :id="triggerId">
        <slot></slot>
    </div>
</template>
<script lang="ts">
import tippy from 'tippy.js';
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        keyId: {
            type: String,
            required: true
        },
        content: {
            type: String,
            default: ''
        },
    },
    computed: {
        triggerId(): string {
            return `tooltip-${this.keyId}`
        }
    },
    mounted() {
        tippy(`#${this.triggerId}`, {
            content: this.content,
            allowHTML: true,
            hideOnClick: false,
            placement: 'left',
        });
    },
})
</script>

<style></style>