
import { defineStore } from 'pinia'

export interface IState {
  session: any | null
  access_token: string
}

function getStateFromStorage(): IState {
  const localState = localStorage.getItem(process.env.VUE_APP_API_STATE_KEY as string)
  const s: IState = JSON.parse(localState || '{}')
  let st: IState = {
    session: s.session,
    access_token: s.access_token,
  }
  return st
}
function updateStateStorage(params: IState): void {
  localStorage.setItem(process.env.VUE_APP_API_STATE_KEY as string, JSON.stringify(params))
}


export const useMainStore = defineStore('main', {
  state: (): IState => getStateFromStorage(),
  actions: {
    updateState(params: Partial<IState>) {
      const updatedState = { ...this.$state, ...params }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    saveSession(params: any) {
      const updatedState = { ...this.$state, session: params }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    saveAuthToken(token: string) {
      const updatedState = { ...this.$state, access_token: token }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    clearState() {
      const updatedState = {
        session: null,
        access_token: '',
        new_appointment_info: null,
        register_info: null
      }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    updateSession(params: any) {
      let sessionParams = {
        ...this.$state.session,
        ...params
      }
      this.updateState({ session: sessionParams })
    },
  }
})
