import "moment/locale/pt-br";
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import UserService from '@/services/UserService'
import paths from '@/router/paths'
import LoadingContainer from '@/components/LoadingContainer.vue'
import EmptyState from '@/components/EmptyState.vue'
import SimpleEmptyState from '@/components/SimpleEmptyState.vue'
import Container from '@/components/Container.vue'
import SearchBar from '@/components/SearchBar.vue'
import TdLink from '@/components/TdLink.vue'
import Tooltip from '@/components/Tooltip.vue'
import TextInput from '@/components/FormComponents/TextInput.vue'
import MoneyInput from '@/components/FormComponents/MoneyInput.vue'
import SelectInput from '@/components/FormComponents/SelectInput.vue'
import TextAreaInput from '@/components/FormComponents/TextAreaInput.vue'
import PasswordInput from '@/components/FormComponents/PasswordInput.vue'
import ImageCropper from '@/components/ImageCropper/ImageCropper.vue'
import FormButton from '@/components/FormComponents/FormButton.vue'
import TableHead from '@/components/TableHead.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Header from '@/components/Header.vue'
import '@/assets/theme/theme.scss'
import 'bootstrap'
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import '@/assets/js/jquery.js'
import '@/utils/Prototypes/prototypeFunctions.ts'
import { EventBus } from "@/utils/EventBus";
import money from 'v-money3'
import ConfirmationAlert from "@/components/ConfirmationAlert/ConfirmationAlert.vue";
import ProductService from "./services/ProductService";
import CategoryService from "./services/CategoryService";
import AutocompleteSelectInput from "./components/FormComponents/AutocompleteSelectInput/AutocompleteSelectInput.vue";
import EditorInput from "./components/FormComponents/EditorInput.vue";

const pinia = createPinia()
const app = createApp(App);

app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$productService = new ProductService()
app.config.globalProperties.$categoryService = new CategoryService()
app.config.globalProperties.$paths = paths;
app.config.globalProperties.$eventBus = EventBus;
app.config.globalProperties.$baseImgUrl = process.env.VUE_APP_BASE_IMG_URL;



app.component("Navbar", Navbar);
app.component("Sidebar", Sidebar);
app.component("LoadingContainer", LoadingContainer);
app.component("EmptyState", EmptyState);
app.component("SimpleEmptyState", SimpleEmptyState);
app.component("Container", Container);
app.component("SearchBar", SearchBar);
app.component("TextInput", TextInput);
app.component("SelectInput", SelectInput);
app.component("TextAreaInput", TextAreaInput);
app.component("PasswordInput", PasswordInput);
app.component("FormButton", FormButton);
app.component("TableHead", TableHead);
app.component("ModalComponent", ModalComponent);
app.component("Header", Header);
app.component("ConfirmationAlert", ConfirmationAlert);
app.component("ImageCropper", ImageCropper);
app.component("AutocompleteSelectInput", AutocompleteSelectInput);
app.component("EditorInput", EditorInput);
app.component("MoneyInput", MoneyInput);
app.component("TdLink", TdLink);
app.component("Tooltip", Tooltip);


app.use(money)
    .use(pinia)
    .use(router).mount('#app')
