import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";
import { ILogin } from "@/interfaces";
import moment from "moment";

export default class UserService {
  network
  constructor() {
    this.network = new NetworkService();
  }
  async login(params: ILogin) {
    var login = await this.network.post(Endpoint.login, params);
    const store = useMainStore()
    store.saveAuthToken(login.access_token || '')
    return login.data
  }
  listOrders(params: any = {}) {
    return this.network.get(Endpoint.orders, params);
  }
  showOrder(id: number | string) {
    return this.network.get(`${Endpoint.orders}/${id}`);
  }
  prepareOrder(item: any) {
    try {
      item.formatted_date = moment(item.created_at).format('DD/MM/YYYY HH:mm')
      item.formatted_total = item.total.toCurrency(item.total)
      item.formatted_address = `${item.street}, ${item.number} - ${item.neighborhood} - ${item.city} ${item.state}`
    } catch (e) {
      console.log(e)
    } finally {
      return item
    }
  }
}
