const paths = {
    not_found: '/not-found',
    login: '/login',
    home: '/produtos',
    categories: '/categorias',
    orders: {
        list: '/pedidos',
        detail: '/pedidos/detalhes',
    },
    products: {
        list: '/produtos',
        detail: '/produtos/detalhes',
        add: '/produtos/adicionar',
        edit: '/produtos/editar',
    },
}

export default paths