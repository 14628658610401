import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", null, [
    _createElementVNode("a", { href: $props.href }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_1)
  ]))
}