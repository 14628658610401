<template>
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
    :class="{ 'navbar-black': userSession?.type === 'master' }" id="sidebar">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link :to="$paths.home" class="navbar-brand text-start d-flex align-items-center">
        <img class="navbar-brand-img" src="@/assets/img/sidebar_logo.png" :alt="appName" />
        <span style="font-size: 0.7rem" class="text-muted ms-3">
          {{ appName }}
        </span>
      </router-link>

      <!-- <div class="navbar-user d-md-none">
        <div class="dropdown">
          <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-sm">
              <i class="fas fa-user-circle display-4 text-light"></i>
            </div>
          </a>

          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <a href="#" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div> -->

      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <hr class="navbar-divider" />
        <h6 class="navbar-heading mb-0">Cadastros</h6>
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.products.list">
            <li class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fas fa-wine-bottle"></i>
                Produtos
              </a>
            </li>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.categories">
            <li class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fas fa-list-check"></i>
                Categorias
              </a>
            </li>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.orders.list">
            <li class="nav-item">
              <a class="nav-link">
                <i class="sidebar-icon fas fa-file-lines"></i>
                Pedidos
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from '@/store';
import { storeToRefs } from 'pinia';

export default {
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return { userSession: session }
  },
  mounted() {
  },
  data() {
    return {
      appName: `${process.env.VUE_APP_APP_NAME.toUpperCase()} ${process.env.VUE_APP_ENV_STRING}`
    }
  }
}
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
