import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useMainStore } from '@/store'
import paths from './paths';
const routes: Array<RouteRecordRaw> = [
  {
    path: `${paths.login}`,
    name: 'Login',
    component: () => import('@/views/Login/Login.vue')
  },
  {
    path: `${paths.products.list}`,
    name: 'Products',
    alias: '/',
    component: () => import('@/views/Products/Products.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.products.add}`,
    name: 'ProductAdd',
    component: () => import('@/views/Products/ProductAdd/ProductAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.products.edit}/:id`,
    name: 'ProductEdit',
    props: true,
    component: () => import('@/views/Products/ProductAdd/ProductAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.categories}`,
    name: 'Categories',
    component: () => import('@/views/Categories/Categories.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.orders.list}`,
    name: 'Orders',
    component: () => import('@/views/Orders/Orders.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.orders.detail}/:id`,
    name: 'OrderDetail',
    props: true,
    component: () => import('@/views/Orders/OrderDetail/OrderDetail.vue'),
    meta: { requiresAuth: true }
  },

  // {
  //   path: `${paths.settings}/:id`,
  //   name: 'Settings',
  //   props: true,
  //   component: () => import('@/views/Settings/Settings.vue'),
  // },
  // {
  //   path: `${paths.more}`,
  //   name: 'Mais',
  //   component: () => import('@/views/More/More.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: `${paths.scheduleDate}`,
  //   name: 'AppointmentScheduleDate',
  //   component: () => import('@/views/AppointmentScheduleDate/AppointmentScheduleDate.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: `${paths.checkout}`,
  //   name: 'Checkout',
  //   component: () => import('@/views/Checkout/Checkout.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: `${paths.psiDetail}/:id?`,
  //   name: 'PsiDetail',
  //   props: true,
  //   component: () => import('@/views/PsiDetail/PsiDetail.vue'),
  // },
  // {
  //   path: `${paths.appointments.detail}/:id`,
  //   name: 'Appointment Detail',
  //   props: true,
  //   component: () => import('@/views/Appointments/AppointmentDetail/AppointmentDetail.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: `${paths.callScreen}/:callId`,
  //   name: 'Live Call',
  //   props: true,
  //   component: () => import('@/views/LiveCallScreen/LiveCallScreen.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: `${paths.passwordReset}`,
  //   name: 'Password Reset',
  //   component: () => import('@/views/PasswordReset/PasswordReset.vue'),
  // },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useMainStore()
  const isLoggedIn = !!store.session;
  if (to.meta.requiresAuth && !isLoggedIn) {
    next(paths.login);
  } else {
    next();
  }
});

export default router
